import styled from 'styled-components'

import { FontWeight, FontFamily } from 'src/styles/typography'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media all and (min-width: 768px) {
  }

  @media all and (min-width: 1200px) {
    justify-content: flex-start;
  }
`

export const InputWrapper = styled.div<{ isError: boolean }>`
  margin-bottom: 1rem;

  @media all and (max-width: 480px) {
    ${(props) => props.isError === true && `margin-bottom: 0;`}
  }

  @media all and (min-width: 768px) {
    margin-bottom: 0;
  }
`

export const InnerInputWrapper = styled.div`
  overflow: hidden;
  background: linear-gradient(
    89.62deg,
    #ff7e44 -1.07%,
    #1db954 49.97%,
    rgba(229, 46, 229, 0.7) 119%
  );
  padding: 2px;
  border-radius: 11px;
  display: flex;

  @media all and (min-width: 768px) {
    margin-bottom: 12px;
  }
`

export const Input = styled.input`
  padding: 0.875rem /* 14px */;

  background: #111111;
  box-sizing: border-box;
  border-radius: 11px;
  height: 52px;
  width: 100%;
  color: white;
  border: none;

  font-size: 1.125rem;
  line-height: 14px;
  margin-bottom: 0;
  outline: none;

  @media all and (min-width: 768px) {
    width: 323px;
  }
`

export const Button = styled.button`
  /* Reset a <button /> element's appearance */
  appearance: none;
  background: none;
  border: none;
  font-family: inherit;
  font-size: inherit;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 60px;
  color: #ffffff;
  border-radius: 11px;
  padding: 0 2rem;
  cursor: pointer;

  font-size: 14px;
  line-height: 14px;
  font-family: ${FontFamily.Inter};
  font-weight: ${FontWeight.SemiBold};
  background: linear-gradient(0deg, #a727a7, #a727a7);

  @media all and (min-width: 768px) {
    font-size: 16px;
  }

  @media all and (min-width: 1200px) {
    font-size: 20px;
  }
`

export const FormErrorMessageWrapper = styled.div`
  width: 323px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SuccessMessage = styled.p`
  font-family: ${FontFamily.Inter};
  font-weight: ${FontWeight.Regular};
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #d6d3d3;
  text-align: center;

  @media all and (min-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }

  @media all and (min-width: 1200px) {
    font-size: 20px;
     line-height: 29px;
  }
`
