import styled, { css } from 'styled-components'

export enum FontFamily {
  Inter = '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  Circular = '"Circular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
}

export enum FontWeight {
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
}

export const SmallHeaderText = css`
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;

  color: #78717e;
`

export const BodyTextParagraph = css`
  font-size: 16px;
  line-height: 26px;

  @media all and (min-width: 768px) {
    font-size: 1.25rem;
    line-height: 30px;
  }

  color: #292a2c;
`

export const CanelaHeader = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;

  @media all and (min-width: 768px) {
    font-size: 2rem;
    line-height: 50px;
  }

  @media all and (min-width: 1200px) {
    font-size: 2.5rem;
    line-height: 60px;
  }
`
