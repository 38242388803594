import { createGlobalStyle } from 'styled-components'

import { BrandColors } from 'src/styles/theme'
import { FontFamily, FontWeight } from 'src/styles/typography'

const GeneralCSS = createGlobalStyle`
*, *::before, *::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  font-family: ${FontFamily.Inter};
  font-weight: ${FontWeight.Regular};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #F5F5F5;
  margin: 0;
}

a {
  color: inherit;
}

.active {
  color: ${BrandColors.CanelaText}
}
`

export default GeneralCSS
