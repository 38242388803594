import * as React from 'react'
import { createGlobalStyle } from 'styled-components'

const FONTS = [
  {
    woffUrl: require('./fonts/Inter/Inter-Regular.woff'),
    woff2Url: require('./fonts/Inter/Inter-Regular.woff2'),
    weight: '400',
  },
  {
    woffUrl: require('./fonts/Inter/Inter-SemiBold.woff'),
    woff2Url: require('./fonts/Inter/Inter-SemiBold.woff2'),
    weight: '600',
  },
]

const CSS = createGlobalStyle`
  ${FONTS.reduce(
    (prev, font) => `
      ${prev}
      @font-face {
        font-display: fallback;
        font-family: "Inter";
        font-style: normal;
        font-weight: ${font.weight};

        src: url("${font.woff2Url}") format("woff2"),
             url(${font.woffUrl}) format("woff"),
      }
    `,
    '',
  )}
`
const Inter = () => <CSS />

export default Inter
