/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

interface Props {
  description?: string
  lang?: string
  meta?: []
  title: string
  ogImage?: string
  ogImageHeight?: number
  ogImageWidth?: number
}

const SEO = ({
  description,
  lang,
  meta,
  title,
  ogImage,
  ogImageHeight,
  ogImageWidth,
}: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description
  const metaTitle = title || site.siteMetadata.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`${site.siteMetadata.title}`}
    >
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogImageWidth && (
        <meta property="og:image:width" content={ogImageWidth.toString()} />
      )}
      {ogImageHeight && (
        <meta property="og:image:height" content={ogImageHeight.toString()} />
      )}
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:card" content="summary_large_image" />
      {ogImage && <meta name="twitter:image" content={ogImage} />}

      <script
        async
        defer
        data-domain="onykp.com"
        src="https://plausible.io/js/plausible.js"
      ></script>
    </Helmet>
  )
}

export default SEO
