import * as React from 'react'
import { createGlobalStyle } from 'styled-components'

const FONTS = [
  {
    otfUrl: require('./fonts/Circular/circular-medium.otf'),
    weight: '500',
  },
  {
    otfUrl: require('./fonts/Circular/circular-bold.otf'),
    weight: '700',
  },
]

const CSS = createGlobalStyle`
  ${FONTS.reduce(
    (prev, font) => `
      ${prev}
      @font-face {
        font-display: fallback;
        font-family: "Circular";
        font-style: normal;
        font-weight: ${font.weight};

        src: url("${font.otfUrl}") format("opentype"),
      }
    `,
    '',
  )}
`
const Circular = () => <CSS />

export default Circular
