import styled, { keyframes, css } from 'styled-components'

import { FontWeight, FontFamily } from 'src/styles/typography'

import cd from './images/CD.png'
import grammy from './images/Floating_Grammy.png'
import mic from './images/Mic.png'
import radio from './images/Radio.png'
import bg from './images/bg.svg'


const beat = keyframes`
  15% { transform: scale(1.25); }
  20% { transform: scale(1.0) }
  100% { transform: scale(1.0) }
`

const float = keyframes`
  from {
    transform: translate(10px, 10px) rotate(10deg);
  }

  to {
    transform: translate(65px, 50px) rotate(0);
  }
`

const float2 = keyframes`
  from {
    transform: translate(-10px) rotate(10deg);
  }

  to {
    transform: translate(40px) rotate(0);
  }
`

const float3 = keyframes`
  from {
    transform: translateX(35px) rotate(30deg);
  }

  to {
    transform: translateX(-40px) rotate(0);
  }
`

const bubbleDefault = css`
  position: absolute;
  display: flex;
  height: 72px;
  width: 72px;
  border-radius: 50%;
  align-items: flex;
  background: hotpink;
  animation: ${float} linear infinite 35s alternate;
  background-size: cover;
`

export const Root = styled.section`
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1.25rem 1.25rem 4rem;

  background-color: rgb(11 10 10);
  background-image: url("${bg}");
  background-position-x: 50%;
  background-position-y: top;
  background-repeat: repeat;

  color: #f5f5f5;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: linear-gradient( 
180deg
, rgb(11 10 10) 31.77%, rgba(11, 10, 10, 0.900763) 58.33%, rgba(11, 10, 10, 0) 100%);
  }

  @media all and (min-width: 768px) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    min-height: 100vh;
  }
`

export const Header = styled.header`
  position: relative;

  @media all and (min-width: 1200px) {
    padding: 0 4.375rem;
  }
`

export const NLogo = styled.img<{ animateLogo: boolean }>`
  opacity: 0;

  ${(props) =>
    props.animateLogo
      ? `   transition: all .5s;
            opacity: 1;
            transform: translateX(-3px);`
      : ''}

  @media all and (min-width: 1200px) {
    opacity: 0;
    transition: all 0.5s;
    transform: translateX(-8px);
  }
`

export const HeaderWrapper = styled.nav`
  width: fit-content;

  a {
    display: flex;
    align-items: center;
    transition: transform 0.5s;

    &:hover {
      ${NLogo} {
        opacity: 1;
        transform: translateX(-3px);
      }
    }
  }
`

export const OLogo = styled.img`
  transition: transform 0.5s;
`

export const ContentWrapper = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5rem;

  @media all and (min-width: 768px) {
    margin-top: 5rem;
  }

  @media all and (min-width: 1200px) {
    margin-top: 3.5rem;
  }
`

export const Heading = styled.h1`
  font-family: ${FontFamily.Circular};
  font-weight: ${FontWeight.Bold};
  text-align: center;
  font-size: 26px;
  line-height: 30px;
  margin: 0;
  margin-bottom: 38px;

  background: linear-gradient(
    89.62deg,
    #ff7e44 16.61%,
    #1db954 51.86%,
    rgba(229, 46, 229, 0.7) 87.85%
  );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

       @media all and (min-width: 768px) {
    font-size: 40px;
    line-height: 48px;
    max-width: 500px;
  }

  @media all and (min-width: 1200px) {
    font-size: 50px;
    line-height: 55px;
    max-width: 713px;
  }
`

export const ParapgraphWrapper = styled.div`
  max-width: 642px;
  text-align: center;
  max-width: 51ch;
  margin-bottom: 28px;
`

export const bubble_1 = styled.span`
  ${bubbleDefault};
  background-image: url("${radio}");
  top: ${20 * Math.random()}%;
  left: ${35 * Math.random()}%;
  animation-duration: 15s;
`

export const bubble_2 = styled.span`
  ${bubbleDefault};
  top: ${30 * Math.random()}%;
  left: ${55 + 20 * Math.random()}%;
  animation-duration: 25s;
  animation-name: ${float3};
  background-image: url("${grammy}");

`

export const bubble_3 = styled.span`
  ${bubbleDefault};
  top: ${80 + Math.abs(10 * Math.random())}%;
  left: ${35 * Math.random()}%;
  background-image: url("${mic}");
  animation-name: ${float2};
  animation-duration: 140s;
`

export const bubble_4 = styled.span`
  ${bubbleDefault};
  top: ${75 + Math.abs(20 * Math.random())}%;
  left: ${70 + 20 * Math.random()}%;
  animation-name: ${float3};
  animation-duration: 60s;
  background-image: url("${cd}");
`

export const Parapgraph = styled.p`
  font-family: ${FontFamily.Inter};
  font-weight: ${FontWeight.Regular};
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #d6d3d3;

  @media all and (min-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }

  @media all and (min-width: 1200px) {
    font-size: 20px;
     line-height: 29px;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 23px;

  @media all and (max-width: 480px) {
    width: 85%;
  }
`

export const SignupButton = styled.button`
  /* Reset a <button /> element's appearance */
  appearance: none;
  background: none;
  border: none;
  font-family: inherit;
  font-size: inherit;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 60px;
  color: #ffffff;
  border-radius: 8px;
  padding: 0 2rem;
  cursor: pointer;

  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  font-family: ${FontFamily.Inter};
  font-weight: ${FontWeight.SemiBold};
  background-color: #1db954;

  @media all and (min-width: 768px) {
    font-size: 16px;
  }

  @media all and (min-width: 1200px) {
    font-size: 20px;
  }
`

export const Footnote = styled.div`
  margin-top: 46px;
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const FootnoteHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const FootnoteHeader = styled.p`
  color: #ffffff;
  margin-right: 10px;
  opacity: 0.4;
  font-size: 18px;
  line-height: 20px;
`

export const FootnoteContent = styled.div`
  position: relative;
  width: 90%;
  background: #fff;
  border-radius: 8px;

  @media all and (min-width: 768px) {
    width: 382px;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    pointer-events: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 90%
    );
    width: 100%;

    width: 100%;
    height: 41px;
  }
`

export const FootnoteContentInner = styled.div`
  padding-top: 24px;
  padding-left: 26px;
  padding-right: 22px;
  height: 268px;
  overflow: scroll;
`

export const FootnoteText = styled.p`
  font-size: 16px;
  line-height: 26px;
  color: #141414;

  margin-top: 0;
  margin-bottom: 25px;
`

export const FootnoteMedia = styled.img`
  height: 50px;
  position: absolute;
  right: -27px;
  top: -20px;
  animation: ${beat} 1.2s infinite alternate 3s;
  transform-origin: left center;
`

/* Heart beat animation */