import styled from 'styled-components'

export const FormErrorsWrapper = styled.span`
  display: flex;
  align-items: center;
  padding: 5px 0;
`
export const FormErrorsIcon = styled.span`
  margin-right: 5px;

  svg {
    display: block;
  }
`
export const Text = styled.span`
  color: #bf0711;
  font-size: 12px;
  text-align: center;
`
