import * as React from 'react'

import Circular from './Circular'
import Inter from './Inter'
import GeneralCSS from './GeneralCSS'
import ResetCSS from './ResetCSS'

const GlobalCSS = () => (
  <>
    <Inter />
    <Circular />
    <GeneralCSS />
  </>
)

export default GlobalCSS
