import React from 'react'

import * as Styles from './styles'

const FormErrorMessage: React.FC<{ message: string }> = ({ message }) => {
  return (
    <Styles.FormErrorsWrapper>
      <Styles.Text>{message}</Styles.Text>
    </Styles.FormErrorsWrapper>
  )
}

export default FormErrorMessage
