import { css } from 'styled-components'

export enum BrandColors {
  Flutterwave = '#f5a623',
  Clue = '#e63733',
  Blue = '#1c336a',
  Purple = '#ECE1F7',
  CanelaText = '#292A2C',
}

export const MAX_CONTENT_WIDTH = '75rem' // 1200px

const ButtonReset = css`
  appearance: none;
  background: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
`

export const ALink = css`
  color: #ff8e43;
  font-size: 1.25rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const Button = css`
  ${ButtonReset}

  background: #524460;
  color: #ffffff;
  border-radius: 6px;

  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-decoration: none;

  padding: 0.6rem 1.5rem;
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    opacity: 0.75;
  }

  @media all and (min-width: 768px) {
    font-size: 1.25rem;
  }
`

export const PageProperty = css`
  padding: 0 1rem;

  @media all and (min-width: 768px) {
    padding: 0 2.25rem;
  }

  @media all and (min-width: 1024px) {
    padding: 0 4.25rem;
  }
  @media all and (min-width: 1200px) {
    padding: 0 6.25rem;
  }
`
