import addToMailchimp from 'gatsby-plugin-mailchimp'
import React, { useState } from 'react'

import FormErrorMessage from 'src/components/FormErrorMessage'
import * as Styles from './styles'

const NewletterForm = () => {
  const [hasSignedUp, setHasSignedUp] = useState(false)
  const [isError, setIsError] = useState(false)
  const [email, setEmail] = useState('')

  const signUp = (event) => {
    event.preventDefault()

    setIsError(false)

    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      .then((data) => {
        console.log("🚀 ~ file: index.tsx ~ line 19 ~ .then ~ data", data)
        if (data.result === 'error') {
          setIsError(true)
          return
        }
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        setEmail('')
        setHasSignedUp(true)
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      })
  }

  return (
    <>
      {hasSignedUp ? (
        <Styles.SuccessMessage>
          Thanks for signing up! We'll let you know when ONYKP is available.
        </Styles.SuccessMessage>
      ) : (
        <Styles.Form onSubmit={signUp}>
          <Styles.InputWrapper isError={isError}>
            <Styles.InnerInputWrapper>
              <Styles.Input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                type="email"
                required={true}
                placeholder="Enter your email"
              />
            </Styles.InnerInputWrapper>

            {isError && (
              <Styles.FormErrorMessageWrapper>
                <FormErrorMessage
                  message={
                    'Oops! Looks like you already signed up to be notified'
                  }
                />
              </Styles.FormErrorMessageWrapper>
            )}
          </Styles.InputWrapper>

          <Styles.Button>Sign up for early access</Styles.Button>
        </Styles.Form>
      )}
    </>
  )
}

export default NewletterForm
