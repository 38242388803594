import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'

import GlobalCSS from 'src/components/GlobalCSS'
import NewsletterForm from './NewsletterForm'
import SEO from 'src/utils/seo'
import * as Styles from './styles'

import oLogo from './images/o-logo.svg'
import nLogo from './images/nykp-logo.svg'
import caret from './images/caret.svg'
import speaker from './images/speaker.png'
import OGImage from './images/Twitter.png'

const OG_IMAGE_SRC = 'https://onykp.com' + OGImage
const OG_IMAGE_WIDTH = 1012
const OG_IMAGE_HEIGHT = 506

export const Home = () => {
  const [showNLogo, setShowNLogo] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNLogo(true)
    }, 500)

    return () => clearTimeout(timer)
  })

  return (
    <>
      <GlobalCSS />
      <Styles.Root>
        <Styles.Header>
          <Styles.HeaderWrapper>
            <Link to="/">
              <Styles.OLogo src={oLogo}></Styles.OLogo>
              <Styles.NLogo animateLogo={showNLogo} src={nLogo}></Styles.NLogo>
            </Link>
          </Styles.HeaderWrapper>
        </Styles.Header>

        <Styles.bubble_1 />
        <Styles.bubble_2 />
        <Styles.bubble_3 />
        <Styles.bubble_4 />

        <Styles.ContentWrapper>
          <Styles.Heading>
            Create interactive playlists that 🌟spark joy🌟
          </Styles.Heading>

          <Styles.ParapgraphWrapper>
            <Styles.Parapgraph>
              ONYKP allows you to curate music and add seemingly unknown details
              that make for a richer experience when listening to an artiste.
            </Styles.Parapgraph>
          </Styles.ParapgraphWrapper>

          <Styles.ButtonWrapper>
            <NewsletterForm />
          </Styles.ButtonWrapper>
        </Styles.ContentWrapper>

        <Styles.Footnote>
          <Styles.FootnoteHeaderWrapper>
            <Styles.FootnoteHeader>Why should I sign up?</Styles.FootnoteHeader>
            <img src={caret}></img>
          </Styles.FootnoteHeaderWrapper>

          <Styles.FootnoteContent>
            <Styles.FootnoteMedia src={speaker}></Styles.FootnoteMedia>
            <Styles.FootnoteContentInner>
              <Styles.FootnoteText>
                Creating curated music playlists is an art form and could be
                arguably compared to creating original music. However, creating
                playlists on music apps can be limiting.
              </Styles.FootnoteText>

              <Styles.FootnoteText>
                Right now, if you were to recommend an artiste to someone, you'd
                probably send the artiste's Spotify page, or their "This is..."
                playlist or even their best album. But there's so much more that
                can be added like the motivation behind a song or little-known
                facts which can make the listening experience better.
              </Styles.FootnoteText>
            </Styles.FootnoteContentInner>
          </Styles.FootnoteContent>
        </Styles.Footnote>
      </Styles.Root>
      <SEO
        title="ONYKP"
        ogImage={OG_IMAGE_SRC}
        ogImageWidth={OG_IMAGE_WIDTH}
        ogImageHeight={OG_IMAGE_HEIGHT}
      />
    </>
  )
}

export default Home
